import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import svgjson from 'svgson';

import {
  Button,
  Layout,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Radio, Space, Tabs } from 'antd';

import {
  clearConfiguration,
  getConfiguration,
  updateConfiguration,
} from './Configuration.slice';
import CustomForm from '../../components/CustomForm';
import { colors, statusOption, statusUnit } from '../../helpers/constants';
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
const { Content, Sider, Header, Footer } = Layout;

export function Configuration({ configurationId, expanded, onClose }) {
  const { configuration, loading } = useAppSelector(
    (state) => state.configuration,
  );

  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [loadingSvg, setLoadingSvg] = useState(false);

  useEffect(() => {
    dispatch(getConfiguration('valoribox'));
    return () => {};
  }, []);

  if (configuration === null) {
    return <Skeleton active />;
  }

  const { front, back, design } = configuration;

  const m2Exposant = (
    <span>
      m<sup>2</sup>
    </span>
  );

  const percentExposant = <span>%</span>;

  const inputsAdmin = [
    {
      parentLabel: 'Configuration',
      children: [
        {
          label:
            "Url du SVG pour tout le projet (important de mettre l'url d'image en absolu )",
          placeholder: 'url du svg',
          name: 'back.svg_uri',
          capitalize: false,
          required: true,
          value: back.svg_uri,
          type: 'input',
          col: 12,
        },
        {
          label: 'Url du SVG Nord',
          placeholder: 'url du svg Nord',
          name: 'back.svg_north',
          capitalize: false,
          value: back.svg_north,
          type: 'input',
          col: 12,
        },
        {
          label: 'Url du SVG Sud',
          placeholder: 'url du svg Sud',
          name: 'back.svg_south',
          capitalize: false,
          value: back.svg_south,
          type: 'input',
          col: 12,
        },
        {
          label: 'Url du SVG Ouest',
          placeholder: 'url du svg Ouest',
          name: 'back.svg_west',
          capitalize: false,
          value: back.svg_west,
          type: 'input',
          col: 12,
        },
        {
          label: 'Url du SVG East',
          placeholder: 'url du svg East',
          name: 'back.svg_east',
          capitalize: false,
          value: back.svg_east,
          type: 'input',
          col: 12,
        },
        {
          label:
            'Orientation principale (attention, ceci définira le SVG de synchronisation)',
          name: 'back.svg_main',
          value: back.svg_main,
          type: 'select',
          selectContent: [
            { value: 'north', label: 'Nord' },
            { value: 'south', label: 'Sud' },
            { value: 'west', label: 'Ouest' },
            { value: 'east', label: 'Est' },
          ],
          col: 12,
        },
      ],
    },
    {
      parentLabel: 'Autre',
      children: [
        // {
        //   required: true,
        //   label: 'Prix',
        //   placeholder: 'Montant du loyer mensuel',
        //   addonBefore: 'CHF',
        //   name: 'info.price',
        //   value: '',
        //   type: 'input-currency',
        //   col: 4,
        // },
      ],
    },
    {
      children: [
        {
          label: 'Sauvegarder',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'right',
        },
      ],
    },
  ];
  const inputsDesign = [
    {
      parentLabel: 'Couleurs',
      children: [
        {
          label: 'Couleur principale',
          placeholder: 'Couleur hexa',
          name: 'design.mainColor',
          value: design.mainColor,
          type: 'input',
          col: 4,
        },
      ],
    },
    {
      children: [
        {
          label: 'Sauvegarder',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'right',
        },
      ],
    },
  ];

  console.log(front);

  const inputsSelect = [
    {
      parentLabel: 'Global',
      children: [
        {
          label: 'Mode affichage ( vente ou location )',
          name: 'front.type_price',
          value: front.type_price,
          type: 'select',
          selectContent: [
            { value: 'rent', label: 'Location' },
            { value: 'sell', label: 'Vente' },
          ],
          col: 6,
        },
        {
          label: 'Opacité des lots hover (0.1 - 1.0)',
          placeholder: 'Opacité',
          name: 'front.opacity_hover',
          value: front.opacity_hover,
          addonAfter: percentExposant,
          type: 'number',
          col: 4,
        },
        {
          label: 'Opacité des lots normal (0.1 - 1.0)',
          placeholder: 'Opacité',
          name: 'front.opacity_default',
          value: front.opacity_default,
          addonAfter: percentExposant,
          type: 'number',
          col: 4,
        },
        {
          label: 'Opacité des lots cliqués  (0.1 - 1.0)',
          placeholder: 'Opacité',
          name: 'front.opacity_clicked',
          value: front.opacity_clicked,
          addonAfter: percentExposant,
          type: 'number',
          col: 4,
        },
        {
          label: 'Bordure épaisseur',
          placeholder: 'Epaisseur',
          name: 'front.stroke_hover_width',
          value: front.stroke_hover_width,
          type: 'number',
          col: 4,
        },
        {
          label: 'Bordure couleur',
          capitalize: false,
          placeholder: 'Couleur',
          name: 'front.stroke_hover_color',
          value: front.stroke_hover_color,
          type: 'input',
          col: 4,
        },
      ],
    },
    {
      parentLabel: 'Filtres',
      children: [
        {
          label: 'Status affichés (en tête de filtres)',
          name: 'front.filter_status',
          value: front.filter_status,
          type: 'select',
          multiple: true,
          selectContent: [
            { value: 'rented', label: 'Loué' },
            { value: 'sold', label: 'Vendu' },
            { value: 'reserved', label: 'Réservé' },
            { value: 'available', label: 'Disponible' },
          ],
          col: 4,
        },
        {
          label: 'Filtre par pièces',
          name: 'front.filter_room',
          value: front.filter_room,
          type: 'switch',
          col: 4,
        },
        {
          label: 'Filtre par status',
          name: 'front.show_filter_status',
          value: front.show_filter_status,
          type: 'switch',
          col: 4,
        },
        {
          label: 'Filtre par étages',
          name: 'front.filter_floor',
          value: front.filter_floor,
          type: 'switch',
          col: 4,
        },
        {
          label: 'Slider montant loyer',
          name: 'front.filter_renting_slider',
          value: front.filter_renting_slider,
          type: 'switch',
          col: 4,
        },
        {
          label: 'Slider montant vente',
          name: 'front.filter_selling_slider',
          value: front.filter_selling_slider,
          type: 'switch',
          col: 4,
        },
        {
          label: 'Filtre par surface',
          name: 'front.filter_surface',
          value: front.filter_surface,
          type: 'switch',
          col: 4,
        },
      ],
    },
    {
      parentLabel: 'Détail lot',
      children: [
        {
          label: 'Champs affichés (bas)',
          name: 'front.unit_labels',
          value: front.unit_labels,
          type: 'select',
          multiple: true,
          selectContent: [
            { value: 'surface', label: 'Surface' },
            { value: 'balcon', label: 'Balcon' },
            { value: 'cave', label: 'cave' },
            { value: 'charges', label: 'charges' },
            { value: 'living', label: 'Surface habitable' },
          ],
          col: 6,
        },
        {
          label: 'Champs affichés (haut)',
          name: 'front.unit_labels_top',
          value: front.unit_labels_top,
          type: 'select',
          multiple: true,
          selectContent: [
            { value: 'name', label: 'Nom' },
            { value: 'floor', label: 'Etages' },
            { value: 'numberRoom', label: 'Pièces' },
            { value: 'status', label: 'Status' },
            { value: 'rent', label: 'Loyer' },
          ],
          col: 6,
        },
        {
          label: 'Boutons affichés',
          name: 'front.unit_buttons',
          value: front.unit_buttons,
          type: 'select',
          multiple: true,
          selectContent: [
            { value: 'location', label: 'Demande de location' },
            { value: 'unit', label: 'Fiche de lot' },
          ],
          col: 6,
        },

        {
          label: 'Sauvegarder',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'right',
        },
      ],
    },
  ];
  const formatString = (s) => {
    return Number(s.replace(/[^0-9.-]+/g, ''));
  };
  const onSubmitForm = (data) => {
    console.log(data);
    // @ts-ignore
    dispatch(updateConfiguration({ ...configuration, ...data }));
    onClose();
  };

  const itemsTabs = [
    {
      label: (
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            marginBottom: 0,
            color: ' rgb(0 21 41)',
          }}
          className={''}
        >
          Administration
        </Typography.Title>
      ),
      key: 'backoffice',
      children: (
        <Content>
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
            }}
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <CustomForm
                  dataSource={inputsAdmin}
                  handleSubmit={(data) => onSubmitForm(data)}
                  labelCol={24}
                  wrapperCol={24}
                  resetOnFinish={undefined}
                  refForm={undefined}
                  onValuesChange={undefined}
                />
              </>
            )}
          </div>
        </Content>
      ),
    },
    {
      label: (
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            marginBottom: 0,
            color: ' rgb(0 21 41)',
          }}
          className={''}
        >
          Sélecteur
        </Typography.Title>
      ),
      key: 'Sélecteur',
      children: (
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <CustomForm
                dataSource={inputsSelect}
                handleSubmit={(data) => onSubmitForm(data)}
                labelCol={24}
                wrapperCol={24}
                resetOnFinish={undefined}
                refForm={undefined}
                onValuesChange={undefined}
              />
            </>
          )}
        </div>
      ),
    },
    {
      label: (
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            marginBottom: 0,
            color: ' rgb(0 21 41)',
          }}
          className={''}
        >
          Design
        </Typography.Title>
      ),
      key: 'Design',
      children: (
        <Content>
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
            }}
          >
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <CustomForm
                  dataSource={inputsDesign}
                  handleSubmit={(data) => onSubmitForm(data)}
                  labelCol={24}
                  wrapperCol={24}
                  resetOnFinish={undefined}
                  refForm={undefined}
                  onValuesChange={undefined}
                />
              </>
            )}
          </div>
        </Content>
      ),
    },
  ];

  // @ts-ignore
  return (
    <>
      <Header
        style={{
          padding: 10,
          background: colorBgContainer,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title
          style={{
            marginBottom: 0,
            marginLeft: '20px',
            color: ' rgb(0 21 41)',
            fontWeight: 300,
            fontSize: '25px',
          }}
          className={''}
        >
          Configuration du projet
        </Typography.Title>
      </Header>
      <Tabs
        type="card"
        items={itemsTabs}
        style={{ padding: 20 }}
        size={'large'}
      ></Tabs>
    </>
  );
}
