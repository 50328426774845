import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import Background_login from '../../../assets/background_login.jpeg';
import Logo from '../../../assets/logo_fake.png';
import { AppContext } from '../../../engine/contexts/App.context';
import { useNavigate } from "react-router-dom";
export const Login = () => {
  const { logUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish =  async (values) => {
   const log = await logUser(values.username, values.password);
   if (log) {
     navigate('/');
   }
  };

  return (
    <div
      className={'login-back'}
      style={{ backgroundImage: `url(${Background_login})` }}
    >
      <div className={'login-container'}>
        <div className={'login-box'}>
          <img src={Logo} className={'login-logo'} />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            style={{ width: 'calc(100% - 20px)' }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre nom d'utilisateur !",
                },
              ]}
            >
              <Input
                size={'large'}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe !',
                },
              ]}
            >
              <Input
                size={'large'}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Item>
            <Form.Item>
              {/*<Form.Item name="remember" valuePropName="checked" noStyle>*/}
              {/*  <Checkbox>Remember me</Checkbox>*/}
              {/*</Form.Item>*/}

              <a className="login-form-forgot" href="">
                Mot de passe perdu
              </a>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Connectez-vous
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
