/**
 *
 * AuthRoute
 *
 */

import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AppContext } from '../contexts/App.context';

function AuthRoute({ children, redirectTo }) {
  const app = useContext(AppContext);
  const location = useLocation();

  let redirectUrl;
  if (process.env.NODE_ENV === 'production') {
    redirectUrl = '';
  } else {
    redirectUrl = '';
  }


  // redirect ghost
  if (!app.isConnected) {
    // @ts-ignore
    return <Navigate to={{ pathname: `/auth`, state: { from: location } }} />;
  }

  return children;
}

// : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}

AuthRoute.propTypes = {};

export default AuthRoute;
