// @ts-nocheck

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import React, { useContext } from 'react';
import { Breadcrumb, Button, Layout } from 'antd';
import { UnitsList } from '../../pages/Units/UnitsList/Units.list';
import { Login } from '../../pages/auth/login/Login';
import { AppContext } from '../contexts/App.context';
import { Menu, theme } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { SiderNavigation } from '../../layouts/Sider.navigation';
import {Configuration} from "../../pages/Configuration/Configuration";

const { Content, Sider, Header, Footer } = Layout;
export default function Routing() {
  const { isConnected } = useContext(AppContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute redirectTo="/login">
              <Layout hasSider>
                <SiderNavigation></SiderNavigation>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                  <Outlet />
                  <Footer style={{ textAlign: 'center' }}>
                    Copyright Niseko ©2023
                  </Footer>
                </Layout>
              </Layout>
            </AuthRoute>
          }
        >
          <Route index element={<UnitsList />} />
          <Route path={'configuration'} element={<Configuration />} />
        </Route>

        <Route path="auth" element={<Outlet />}>
          <Route index element={<Login />} />
          <Route path="login" index element={<p>login</p>} />
          <Route path="register" element={<p>register</p>} />
          <Route path="activate" element={<p>activate</p>} />
          <Route path="lost" element={<p>lost</p>} />
          <Route path="reset" element={<p>reset</p>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
