// @ts-nocheck
// @ts-ignore
/* eslint-disable */
/**
 *
 * SelectCustom
 *
 */

import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Link, useHistory } from 'react-router-dom';
// import useDeviceDetect from 'utils/useDeviceDetect';
// styles

// antd component
//import { Card } from 'antd';
//const { Meta } = Card;
// icons
//import CustomIcons from 'components/CustomIcons';
//import defaultUnitImage from 'images/default.jpg';
// components
import { Button, Divider, Select, Skeleton, Space, Tag } from 'antd';

const { Option } = Select;

// global user
// import { withUser } from 'engine/Contexts/User.context';

function SelectCustom({
  // default props
  className,
  children,
  item,
  listOptions,
  renderExtraSelect = false,
  mode,
  allowClear,
  disabled,
  refForm,
  showSearch,
  filterOption,
  filterSort,
  optionFilterProp,
  ready,
  ...rest
}) {
  if (ready === false) {
    return <Skeleton />;
  }
  // Multiple
  const [selectedItems, setSelectedItems] = useState(
    listOptions.map((e) => e.key),
  );

  useEffect(() => {
    setSelectedItems(listOptions.map((e) => e.key));
  }, [listOptions]);

  // Single
  const [selectedItem, setSelectedItem] = useState(null);

  const filteredOptions = listOptions.filter(
    (o) => !selectedItems.includes(o.key),
  );

  useEffect(() => {
    refForm.current.setFieldsValue({ [item.name]: selectedItems });
  }, [selectedItems]);

  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label = listOptions.find((i) => i.key === value)?.label;
    return (
      <Tag
        color={'#00BF9B'}
        closable={closable}
        onClose={onClose}
        style={{ padding: 3, margin: 3, fontSize: 16, fontWeight: 'bold' }}
      >
        {label}
      </Tag>
    );
  };

  const selectAll = () => {
    setSelectedItems(listOptions.map((e) => e.key));
  };
  const invert = () => {
    let newMap = [];

    newMap = listOptions.filter((o) => !selectedItems.includes(o.key));
    setSelectedItems(newMap.map((e) => e.key));
  };
  const deselectAll = () => {
    setSelectedItems([]);
  };

  //const { isMobile } = useDeviceDetect();

  return (
    <Select
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      filterSort={filterSort}
      mode={mode}
      allowClear={allowClear}
      disabled={disabled}
      showSearch={showSearch}
      value={mode === 'multiple' ? selectedItems : selectedItem}
      defaultValue={mode === 'multiple' ? selectedItems : selectedItem}
      onDeselect={(e) => {
        setSelectedItems(selectedItems.filter((o) => o !== e));
      }}
      tagRender={tagRender}
      onSelect={(newItem) => {
        setSelectedItems([...selectedItems, newItem]);
      }}
      dropdownRender={(menu) =>
        renderExtraSelect ? (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />

            <Space style={{ padding: '0 8px 4px' }}>
              <Button type="primary" onClick={selectAll}>
                Tout sélectionner
              </Button>
              <Button type="primary" onClick={invert}>
                Inverser
              </Button>
              <Button type="primary" onClick={deselectAll}>
                Tout déselectionner
              </Button>
            </Space>
          </>
        ) : (
          menu
        )
      }
    >
      {listOptions.map((option, i) => (
        <Option
          {...option}
          key={option?.key || option?.value}
          value={option?.value || option?.key}
          color={option?.color}
        >
          {option?.label}
        </Option>
      ))}
    </Select>
  );
}

SelectCustom.defaultProps = {};
SelectCustom.propTypes = {};

export default memo(SelectCustom);
