/**
 *
 * ColFormItemWrapper
 *
 */

import React from 'react';
import { Col, Form } from 'antd';
import PropTypes from 'prop-types';

// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function uppercase(s) {
  return s.toUpperCase();
}

function capitalize(s) {
  return s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

function removeDecimal(s) {
  if (s === null) {
    return 0;
  }

  return parseFloat(s).toFixed(3).slice(0, -1);
}

function ColFormItemWrapper({ item, index, children }) {
  // TODO deal with initialValue and Value and rehyrate

  const { name, offset, displayLabel, required, rules = [], value, col } = item;

  const defaultRules = [
    {
      required: required || false,
      message: 'Champs obligatoire',
    },
  ];
  const { label, componentLabel, addonAfter, addonBefore, ...rest } = item;
  const extraProps = JSON.parse(JSON.stringify(rest));

  if (item.type === 'submit') {
    extraProps.label = null;
  }

  if (item.type === 'input' && item.capitalize !== false) {
    extraProps.normalize = (value) => capitalize(value);
  }
  if (item.type === 'input' && item.uppercase === true) {
    extraProps.normalize = (value) => uppercase(value);
  }

  if (item.type !== 'dynamic-select') {
    extraProps.initialValue = value;
  } else {
    if (value !== undefined && value !== null) {
      extraProps.initialValue = value;
    }
  }

  if (item.type === 'select') {
    if (value === undefined || value?.length === 0) {
      extraProps.initialValue = undefined;
    } else {
      extraProps.initialValue = value;
    }
  }

  if (item.type === 'input-currency') {
    extraProps.validateTrigger = 'onBlur';
    extraProps.trigger = 'onBlur';
  }

  // if (item.type === 'input-currency') {
  //   extraProps.trigger = 'onValueChange';
  // }

  const renderLabel = () => {
    if (label === undefined && componentLabel === undefined) {
      return null;
    }

    if (componentLabel === undefined) {
      return label;
    }

    if (React.isValidElement(componentLabel)) {
      return componentLabel;
    }
  };

  return (
    <Col
      style={{
        opacity: item.ready === false || item.disabled === true ? 0.4 : 1,
        textAlign: item.align ? item.align : 'left',
      }}
      key={name + index + '-col'}
      span={col}
    >
      <Form.Item
        required={required}
        key={name + index + '-item'}
        name={name}
        label={renderLabel()}
        className={name + '-wrapper'}
        rules={rules.length === 0 ? defaultRules : rules}
        {...extraProps}
      >
        {children}
      </Form.Item>
    </Col>
  );
}

ColFormItemWrapper.propTypes = {
  item: PropTypes.shape({
    col: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    offset: PropTypes.number,
    displayLabel: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
  }),
  rules: PropTypes.array,
  index: PropTypes.number,
  children: PropTypes.node,
};

ColFormItemWrapper.defaultProps = {
  item: {
    rules: [],
  },
};

export default ColFormItemWrapper;
