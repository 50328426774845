// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { http } from '../../../helpers/http';
import { notification } from 'antd';
import { UnitType } from '../../../helpers/Unit.interface';
import { updateUnitOnList } from '../UnitsList/Units.slice';

interface UnitsState {
  unit: UnitType | null;
  loading: boolean;
}

const initialState: UnitsState = {
  unit: null,
  loading: false,
};

export const getUnit = createAsyncThunk(
  'unit/getUnit',
  async (unitId: String, thunkAPI) => {
    const res = await http.get('/units/' + unitId);
    return res.data;
  },
);

export const updateUnit = createAsyncThunk(
  'unit/update',
  async (unit: UnitType, thunkAPI) => {
    const res = await http.patch('/units/' + unit._id, { unit });
    thunkAPI.dispatch(updateUnitOnList(res.data));
    return res.data;
  },
);

const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    clearUnit: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getUnit.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUnit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.unit = payload;
    },
    [updateUnit.pending]: (state, { payload }) => {},
    [updateUnit.fulfilled]: (state, { payload }) => {
      notification.success({
        message: `${payload.id} modifié`,
      });
    },
    [getUnit.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearUnit } = unitSlice.actions;

export const unitReducer = unitSlice.reducer;
