// @ts-nocheck

import { configureStore } from '@reduxjs/toolkit';
import { unitsReducer } from '../pages/Units/UnitsList/Units.slice';
import { unitReducer } from '../pages/Units/UnitDetail/Unit.slice';
import {configurationReducer} from "../pages/Configuration/Configuration.slice";

// @ts-ignore
export const store = configureStore({
  reducer: {
    units: unitsReducer,
    unit: unitReducer,
    configuration: configurationReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
