import { AppProvider } from './engine/contexts/App.context';
import 'antd/dist/reset.css';
import Routing from './engine/routeur/Routing';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from './engine/store';
import './layouts/styles/App.css';
import './layouts/styles/index.css';
import { LoadingIndex } from './layouts/LoadingIndex';
import frFR from 'antd/locale/fr_FR';

function App() {
  return (
    <div className="App">
      <AppProvider>
        <Provider store={store}>
          <ConfigProvider
            locale={frFR}
            theme={{
              token: {
                colorPrimary: '#001529',
              },
            }}
          >
            <LoadingIndex />
          </ConfigProvider>
        </Provider>
      </AppProvider>
    </div>
  );
}

export default App;
