/**
 *
 * AddressForm
 *
 */

import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Link, useHistory } from 'react-router-dom';
// import useDeviceDetect from 'utils/useDeviceDetect';
// styles

// antd component
//import { Card } from 'antd';
//const { Meta } = Card;
// icons
//import CustomIcons from 'components/CustomIcons';
//import defaultUnitImage from 'images/default.jpg';
// components
import { renderInputType } from '../index';
import { Button, Col, Modal } from 'antd';
import cities from '../cities.json';

// global user
// import { withUser } from 'engine/Contexts/User.context';

function AddressForm({
  // default props
  item,
  index,
  autoCompleteSearch = true,
  withLocator = false,
  form,
  disabled = false,
}) {
  const {
    street,
    number,
    zip,
    city,
    district,
    country,
    beneficiary = null,
  } = item.values;

  const [isModalVisibleSearch, setIsModalVisibleSearch] = useState(false);
  const [latitude, setLatitude] = useState(
    item.values.latitude ? item.values.latitude : 46.29766919163871,
  );
  const [longitude, setLongitude] = useState(
    item.values.longitude ? item.values.longitude : 7.054721282984308,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (item.values.latitude && item.values.longitude) {
      form.setFieldsValue({
        ['address.latitude']: item.values.latitude,
        ['address.longitude']: item.values.longitude,
      });
    }
  }, []);

  const forms = [
    {
      placeholder: 'Rue',
      name: 'address.street',
      value: street,
      type: 'input',
      col: 20,
      capitalize: true,
    },
    {
      placeholder: 'Numéro',
      name: 'address.number',
      value: number,
      type: 'input',
      col: 4,
    },
    {
      showSearch: true,
      capitalize: true,
      placeholder: 'NPA',
      name: 'address.zip',
      value: zip,
      type: 'input',
      col: 4,
    },
    {
      placeholder: 'Ville',
      name: 'address.city',
      capitalize: true,
      value: city,
      type: 'input',
      col: 20,
    },
    {
      placeholder: 'Canton',
      capitalize: true,
      name: 'address.district',
      col: 12,
      value: district,
      type: 'input',
    },
    {
      placeholder: 'Pays',
      capitalize: true,
      name: 'address.country',
      value: country,
      type: 'input',
      col: 12,
    },
  ];

  if (beneficiary !== null) {
    forms.unshift({
      capitalize: false,
      placeholder: 'Bénéficiaire',
      name: 'address.beneficiary',
      value: beneficiary,
      type: 'input',
      col: 24,
    });
  }


  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const onHandleMap = latlng => {
    setLatitude(latlng.lat);
    setLongitude(latlng.lng);

    form.setFieldsValue({
      ['address.latitude']: latlng.lat,
      ['address.longitude']: latlng.lng,
    });
  };

  if (withLocator) {
    forms.push({
      type: 'children',
      render: () => {
        return (
          <Col style={{ marginTop: '16px' }}>

            {longitude !== false && (
              <span
                style={{
                  lineHeight: '1.5715',
                  position: 'relative',
                  display: 'inline-block',
                  fontWeight: '400',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  height: '32px',
                  padding: '4px 15px',
                  fontSize: '14px',
                  color: 'rgba(0,0,0,.85)',
                }}
              >
                GPS : {latitude}, {longitude}
              </span>
            )}
          </Col>
        );
      },
    });

    // trick pour avoir les lat sur le submit du form
    forms.push({ type: 'input', name: 'address.latitude', hidden: true });
    forms.push({ type: 'input', name: 'address.longitude', hidden: true });
  }

  return forms.map((champ, index) =>
    renderInputType(champ, index, form, null, disabled),
  );
}

AddressForm.defaultProps = {};
AddressForm.propTypes = {};

export default memo(AddressForm);
