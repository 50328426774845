// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { http } from '../../helpers/http';
import { notification } from 'antd';
import { updatConfigurationOnList } from './Configurations.slice';
import { Configuration } from '../../helpers/Configuration.interface';

interface ConfigurationsState {
  configuration: Configuration | null;
  loading: boolean;
}

const initialState: ConfigurationsState = {
  configuration: null,
  loading: false,
};

export const getConfiguration = createAsyncThunk(
  'configuration/getConfiguration',
  async (configurationId: String, thunkAPI) => {
    const res = await http.get('/configurations/');
    return res.data;
  },
);

export const updateConfiguration = createAsyncThunk(
  'configuration/update',
  async (configuration, thunkAPI) => {
    const res = await http.post('/configurations/', configuration);
    return res.data;
  },
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    clearConfiguration: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getConfiguration.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getConfiguration.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.configuration = payload;
    },
    [updateConfiguration.pending]: (state, { payload }) => {},
    [updateConfiguration.fulfilled]: (state, { payload }) => {
      notification.success({
        message: `Configuration modifiée`,
      });
    },
    [getConfiguration.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearConfiguration } = configurationSlice.actions;

export const configurationReducer = configurationSlice.reducer;
