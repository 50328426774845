// @ts-nocheck
import * as React from 'react';

import { http, setAuthorizationHeader } from '../../helpers/http';
import { apiUrl, localStorageUserKey } from '../../helpers/constants';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';

type ContextType = {
  loading: boolean;
  ready: boolean;
  isConnected: boolean;
  logUser: Function;
  logOut: Function;
};
const defaultContext = {};

export const AppContext = React.createContext<ContextType>(defaultContext);

const initialState = {
  loading: false,
  ready: false,
  isConnected: false,
};

export class AppProvider extends React.Component {
  async componentDidMount() {
    await this.authLocalUser();
  }

  init = async () => {
    // https://json-generator.com/

    // const units = await http.get(`/lots`);

    this.setState({
      ready: true,
    });
  };
  authLocalUser = async () => {
    try {
      const token = localStorage.getItem(localStorageUserKey);

      if (token !== null) {
        // invalid token
        var decoded = jwt_decode(token);

        http.defaults.headers.common.Authorization = 'Token ' + token;

        const response = await http.get(`users/${decoded.id}`);

        if (response.status === 200) {
          this.setState({
            token,
            ...response.data,
            isConnected: true,
            ready: true,
          });
        }
      } else {
        this.setState({
          ready: true,
        });
      }
    } catch (e) {
      console.log(e);
      delete http.defaults.headers.common.Authorization;
    }
  };

  logOut = async () => {
    await localStorage.clear();
    await this.setState({ ...initialState, ready: true });
  };

  logUser = async (email: string, pwd: string) => {
    try {
      const response = await http.post(`auth/login`, {
        email: email.trim(),
        password: pwd,
      });

      await localStorage.setItem(localStorageUserKey, response.data.user.token);

      const { user } = response.data;

      notification.success({
        message: `${'Bienvenue'} ${user.lastname} ${user.firstname} !`,
      });

      await this.authLocalUser();

      return response.data.user;
    } catch (e) {
      notification.error({
        message: `Ces identifiants ne sont pas corrects`,
      });
      console.log(e);
    }
  };

  state = {
    ...initialState,
    logUser: this.logUser,
    logOut: this.logOut,
  };

  render() {
    // @ts-ignore
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}

export const withApp =
  (Component: JSX.Element) => (props: JSX.ElementAttributesProperty) =>
    (
      <AppContext.Consumer>
        {(store) => <Component app={store} {...props} />}
      </AppContext.Consumer>
    );
