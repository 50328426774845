import React, { useContext } from 'react';
import { AppContext } from '../engine/contexts/App.context';
import Routing from '../engine/routeur/Routing';

export const LoadingIndex = () => {
  const { isConnected, ready } = useContext(AppContext);


  if (!ready) {
    return <p>chargement</p>;
  } else {
    return <Routing />;
  }
};
