import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  InsertRowLeftOutlined,
  DashboardOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import React, { useContext } from 'react';
import { AppContext } from '../engine/contexts/App.context';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

export const SiderNavigation = () => {
  const navigate = useNavigate();

  const { logOut } = useContext(AppContext);

  const items2: MenuProps['items'] = [
    {
      key: 'dashboard',
      label: 'Tableau de bord',
      disabled: true,
      icon: React.createElement(DashboardOutlined),
    },
    {
      key: 'unit',
      label: 'Lots',
      icon: React.createElement(InsertRowLeftOutlined),
      onClick: async (d) => {
        navigate('/');
      },
    },
    {
      key: 'stats',
      label: 'Statistiques',
      disabled: true,
      icon: React.createElement(LineChartOutlined),
    },
  ];

  const menuUser: MenuProps['items'] = [
    {
      key: 'configuration',
      label: 'Réglages',
      disabled: false,
      icon: React.createElement(SettingOutlined),
      onClick: async (d) => {
        navigate('/configuration');
      },
    },
    {
      key: 'help',
      disabled: true,
      label: 'Support',
      icon: React.createElement(QuestionCircleOutlined),
    },
    {
      key: 'logout',
      label: 'Se déconnecter',
      icon: React.createElement(LogoutOutlined),
      onClick: async (d) => {
        await logOut();
        navigate('/auth');
      },
    },
  ];

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          height: 32,
          margin: 16,
          background: 'rgba(255, 255, 255, 0.2)',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 80px)',
          justifyContent: 'space-between',
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items2}
        />

        <Menu theme="dark" mode="inline" items={menuUser} />
      </div>
    </Sider>
  );
};
